import { graphql } from "gatsby";
import React from "react";
import Loading from "../../components/atoms/Loading/Loading";
import Translate from "../../components/atoms/Translate/Translate";
import Layout from "../../components/global/Layout/Layout";
import { formatPageData } from "../../components/global/Layout/utils";
import PagePadding from "../../components/templates/PagePadding/PagePadding";
import Section from "../../components/templates/Section/Section";
import { TranslationProvider } from "../../contexts/TranslationContext";
import { navigateToLocalePage } from "../../utils/navigate";
import {
  PageContent,
  PageData,
  SiteLocales,
  SiteSettings,
  SiteTranslations,
} from "../Page/types";

interface Props {
  data: {
    sanitySetting: SiteSettings;
    allSanityLanguage: SiteLocales;
    allSanityTranslation: SiteTranslations;
    sanityPage: PageData;
  };
  uri: string;
  pageContext: PageContent;
}

const RedirectPage = (props: Props) => {
  const site = props.data.sanitySetting;
  const page = formatPageData(site, props.data.sanityPage);

  React.useEffect(() => {
    navigateToLocalePage(
      navigator.language.toLowerCase() || "en",
      window.location.pathname
    );
  }, []);

  return (
    <TranslationProvider
      locale={page.locale}
      translations={props.data.allSanityTranslation}
    >
      <Layout header={page.header} footer={page.footer}>
        <Section>
          <PagePadding>
            <div style={{ margin: "100px 0", textAlign: "center" }}>
              <Loading
                size={100}
                style={{ display: "inline-block", marginBottom: "2rem" }}
              />
              <br />
              <Translate const="page.redirect" />
            </div>
          </PagePadding>
        </Section>
      </Layout>
    </TranslationProvider>
  );
};

export const query = graphql`
  query {
    ...settings
    ...languages
    ...translations
    ...pageByLocale
  }
`;

export default RedirectPage;
